// import { isTestStore } from "config";
// import { isProduction } from "config";
import Auth from "tools/auth";
import { tsNow } from "./time";
import { logDebug } from "./log";

const isEnabled = () => false; // isProduction && !isTestStore && !Auth.isAppTeamUser();

export const initMarketingTools = () => {
  if (isEnabled()) {
    initTwitterPixel();
  }
};

export const emitMarketingEvent = ({ eventId, data }) => {
  if (!isEnabled()) {
    logDebug(
      `Marketing tools not enabled. Ignoring event ${{ eventId, data }}`
    );
    return;
  }

  const shopId = (Auth.getOauthShopHint() || "unknown").replace(
    ".myshopify.com",
    ""
  );

  const dedupeBucketSizeSeconds = 30 * 60;
  const currentTsBucketStart =
    Math.floor(tsNow() / dedupeBucketSizeSeconds) * dedupeBucketSizeSeconds;

  emitTwitterEvent({
    conversionId: `${shopId}::${eventId}::${currentTsBucketStart}`,
    value: JSON.stringify(data),
  });
};

const initTwitterPixel = () => {
  // Initializes twitter pixel that can then be used for emitting events.

  // eslint-disable-next-line no-eval
  eval(`
  !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
  a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
  twq('config','oehcx');
  `);
};

const emitTwitterEvent = ({ conversionId, value }) => {
  if (!window.twq) {
    logDebug(
      `Twitter pixel not installed. Ignoring event ${{ conversionId, value }}`
    );
    return;
  }
  window.twq("event", "tw-oehcx-oeirz", {
    conversion_id: conversionId,
    value,
  });
};
