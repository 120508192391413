// Note: This module gets imported right at the start in index.js
// So everything here gets evaluated first thing on app load.
export const isProduction = process.env.NODE_ENV === "production";

export const isTestStore = window.location.search.includes("shop=gro-");

// Public identifier for Shopify apps.
export const SHOPIFY_API_KEY = isProduction
  ? "5a85653561465ebeac3cf27033a98878"
  : "38d67a9a260d30d660b3103e44d4f5e0";

export const EMBEDDED_APP_HANDLE = isProduction ? "gro-upsell-1" : "gro-upsell";

export const APP_PROXY_PATH_PREFIX = "a/gro1";

export const GRO_PLATFORM_BASE_URL = isProduction
  ? "https://api.gro-upsell.com"
  : "https://gro-upsell-dev-api.com";

export const isDebug =
  !isProduction ||
  window.location.search.includes("gro-debug=") ||
  window.location.pathname.includes("gro-strong");
