// TODO(Raj): Make this a noop on production builds.
export function logDebug() {
  const testStores = ["gro-computers", "gro-strong", "whole-fit"];
  for (let i = 0; i < testStores.length; i++) {
    if (window.location.href.includes(testStores[i])) {
      console.log(new Date().getTime() / 1000.0, ...arguments);
    }
  }
}

// TODO(Raj): These should get to the log aggregator for real world.
export const logInfo = (msg) => console.log(msg);
export const logError = (msg) => console.error(msg);
