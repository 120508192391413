import { executeIntent } from "api";
import React, { useEffect, useState } from "react";

export const ResourceSyncStatus = React.createContext({});

export const ResourceSyncStatusProvider = React.memo(
  ({ initState, children }) => {
    const [syncedResources, setSyncedResources] = useState(
      initState?.synced || []
    );
    const [isComplete, setIsComplete] = useState(
      Boolean(initState?.isComplete)
    );

    useEffect(() => {
      let periodMillis = 2000;
      let timeoutId = null;

      const checkSyncStatus = async () => {
        const result = await executeIntent(
          "shopify/entity_sync_status",
          {},
          {
            ignoreErrors: true,
          }
        );

        const syncedResources = result?.data?.synced || [];
        setSyncedResources(syncedResources);

        const isComplete = Boolean(result?.data?.is_complete);
        setIsComplete(isComplete);
        if (!isComplete) {
          // Defer the next checkSyncStatus call.
          timeoutId = setTimeout(checkSyncStatus, periodMillis);
          periodMillis = Math.min(5000, periodMillis * 1.5);
        }
      };

      // Kick off chain of checkSyncStatus
      if (!isComplete) {
        timeoutId = setTimeout(checkSyncStatus, periodMillis);
      }

      return () => timeoutId && clearTimeout(timeoutId);
    }, [isComplete]);

    return (
      <ResourceSyncStatus.Provider
        value={{
          syncedResources,
          isComplete,
        }}
      >
        {children}
      </ResourceSyncStatus.Provider>
    );
  }
);
