import React, { useEffect, useState } from "react";

import { executeIntent } from "api";
import { areArraysEqual } from "tools/arrays";
import { getCachedShopProp } from "tools/caching";
import { logError } from "tools/log";

// Global state for working with translations.
export const Translations = React.createContext({});

export const TranslationsProvider = ({ children }) => {
  const [translations, setTranslations] = useState(null);

  const loadTranslations = async () => {
    const { data } = await executeIntent("upsells/translations", {});
    setTranslations(data);
  };

  useEffect(() => {
    const timeoutDelay = [
      "/onboarding/autopilot",
      "/onboarding/rule",
      "/",
    ].includes(window.location.pathname)
      ? 2000 + 1000 * Math.random()
      : 0;
    const timeoutId = setTimeout(() => loadTranslations(), timeoutDelay);
    return () => clearTimeout(timeoutId);
  }, []);

  // TODO(Raj): This needs to be asserted in the backend too.
  // Preferably at app load time.
  const allOfferLocales = Object.keys(
    translations?.offers?.one_click_upsell || {}
  );
  const allMerchantLocales = Object.keys(translations?.merchant || {});

  if (!areArraysEqual(allOfferLocales, allMerchantLocales)) {
    logError(
      `Available offer locales are not the same as merchant locales.
      Offer locales: ${JSON.stringify(allOfferLocales)},
      Merchant locales: ${JSON.stringify(allMerchantLocales)}
    `
    );
  }

  let currentLocale = primaryLocale();
  if (!allOfferLocales.includes(currentLocale) && translations !== null) {
    logError(
      `Translations not available for store primary locale: ${currentLocale}. Defaulting to english`
    );
    currentLocale = "en";
  }

  const merchantTranslationsForLocale = (locale) =>
    translations?.merchant?.[locale];
  const merchantTranslations = merchantTranslationsForLocale(currentLocale);

  const offerTranslationsForLocale = (locale) =>
    Object.fromEntries(
      Object.entries(translations?.offers || {}).map((kv) => [
        kv[0],
        kv[1]?.[locale],
      ])
    );
  const offerTranslations = offerTranslationsForLocale(currentLocale);

  return (
    <Translations.Provider
      value={{
        allLocales: allOfferLocales,
        offerTranslationsForLocale,
        offerTranslations,
        merchantTranslationsForLocale,
        merchantTranslations,
        merchantLocale: currentLocale,
      }}
    >
      {children}
    </Translations.Provider>
  );
};

export const primaryLocale = () =>
  (getCachedShopProp("primary_locale") || "en").split("-")[0];
