

// Helper to receive route params for page components.
// The react-router seems to have taken on the responsibility
// for handling loading and error states.

const { useParams } = require("react-router-dom")

// This allows continued use of LoadedComponent wrappers.
export const RouteWrapper = ({ component, componentProps, routeParamsToPassDown }) => {
  const routeParams = useParams()

  componentProps = {
    ...componentProps,
    routeParams
  }

  const routeParamProps = (routeParamsToPassDown || []);
  routeParamProps.forEach(propName => {
    componentProps[propName] = routeParams[propName];
  });

  const Component = component;
  return <Component
    {...componentProps}
  />
}