import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";

import { EMBEDDED_APP_HANDLE, isProduction } from "./config";
import "@shopify/polaris/build/esm/styles.css";
import { initSupportTools } from "tools/support";
import { isShopifyEmbedded } from "@shopify/app-bridge-utils";
import { getQueryParamValue } from "tools/url";
import Auth from "tools/auth";
import { startShopOauth, executeIntent } from "api";
import { isLogRocketEnabled, supportToolsEnabled } from "./tools/support";
import { emitMarketingEvent, initMarketingTools } from "tools/marketing";
import { logDebug, logInfo } from "tools/log";

if (isProduction) {
  Sentry.init({
    dsn: "https://d2c6dbdd05b04133bd14cd0e40ac8923@o4504026259980288.ingest.sentry.io/4504026260832256",
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// HACK: Handle auth redirect scenarios before the react app is mounted.
// The AppBridgeProvider does its own redirects outside our control after
// the app has been mounted.
// Note: This was introduced as part of using per-user access mode oauth
// for merchant app auth - to get a clear idea of who exactly in the merchant team came up.
const isEmbedded = isShopifyEmbedded();
const groAuthJwt = getQueryParamValue("gro_jwt");
const shopName = (getQueryParamValue("shop") || "").split(".")[0];
const currentAppPath = window.location.pathname;

if (!isEmbedded && groAuthJwt) {
  // If landed directly on app with a gro_jwt - redirect back to the embedded app.
  const embeddedAppUrl = `https://admin.shopify.com/store/${shopName}/apps/${EMBEDDED_APP_HANDLE}${currentAppPath}?gro_jwt=${groAuthJwt}`;
  window.location.href = embeddedAppUrl;
} else if (
  shopName &&
  !Auth.isAuthenticated() &&
  !groAuthJwt &&
  !(
    currentAppPath.startsWith("/connect") ||
    currentAppPath.startsWith("/__ta__")
  )
) {
  // If there is no auth payload available and not on /connect, start shopify oauth flow
  // Note: The case of (!isAuthenticated && groAuthJwt) is handled with merchant auth intent in merchant-app/index.js
  logInfo(
    "[Gro]: Not authenticated and no auth token found: Starting Shop Oauth with post auth destination path",
    currentAppPath
  );
  startShopOauth(shopName, currentAppPath);
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  /*
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ); */
  logDebug("App rendering started");

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals((observation) => {
    logDebug(observation);

    if (!Auth.isAuthenticated()) {
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const location = {
      path: window.location.pathname,
      shop: queryParams.get("shop"),
      qs: queryParams.toString(),
    };
    setTimeout(() => {
      executeIntent("gro-core/report-app-domain-event", {
        event_type: "web-vitals-measure-reported",
        data: {
          observation,
          location,
        },
      });
    }, 6000 + Math.random() * 4000);
  });

  if (shopName) {
    Auth.setOauthShopHint(shopName);
  }

  if (isLogRocketEnabled) {
    LogRocket.init("zodjsu/gro-prod");
  }

  if (supportToolsEnabled) {
    initSupportTools();
  }

  initMarketingTools();

  emitMarketingEvent({
    eventId: "app-opened",
    data: {
      app: "gro-upsell",
      shop: shopName,
      path: currentAppPath,
    },
  });
}
