import React, { useState } from "react";
import { tsNow } from "tools/time";

export const OnboardingStatus = React.createContext({});

export const OnboardingStatusProvider = React.memo(
  ({ initState, children }) => {
    const [completedTs, setCompletedTs] = useState(initState?.completed_ts);

    const isOnboardingComplete = Boolean(completedTs);

    const onOnboardingCompleted = () => setCompletedTs(tsNow());

    return (
      <OnboardingStatus.Provider
        value={{
          isOnboardingComplete,
          onOnboardingCompleted,
          onboardingCompletedTs: completedTs,
        }}
      >
        {children}
      </OnboardingStatus.Provider>
    );
  }
);
