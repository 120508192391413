import { executeIntent } from "api";
import React, { useEffect, useState } from "react";
import { logDebug } from "tools/log";

export const AutopilotTrainingStatus = React.createContext({});

export const AutopilotTrainingStatusProvider = React.memo(
  ({ initState, children }) => {
    const [completedTrainings, setCompletedTrainings] = useState(
      initState?.completed || []
    );

    const isComplete = (trainings) => trainings.length > 0;

    useEffect(() => {
      let periodMillis = 2000;
      let timeoutId = null;

      const checkStatus = async () => {
        const result = await executeIntent(
          "upsells/autopilot/training_status",
          {},
          {
            ignoreErrors: true,
          }
        );

        const completedTrainings = result?.data?.completed || [];
        setCompletedTrainings(completedTrainings);

        if (!isComplete(completedTrainings)) {
          // Defer the next checkStatus call.
          timeoutId = setTimeout(checkStatus, periodMillis);
          periodMillis = Math.min(5000, periodMillis * 1.5);
        }
      };

      // Kick off chain of checkStatus
      if (!isComplete(completedTrainings)) {
        logDebug(
          "Autopilot training already complete, not triggering check loop"
        );
        timeoutId = setTimeout(checkStatus, periodMillis);
      }

      return () => timeoutId && clearTimeout(timeoutId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <AutopilotTrainingStatus.Provider
        value={{
          completedTrainings,
          isComplete: isComplete(completedTrainings),
        }}
      >
        {children}
      </AutopilotTrainingStatus.Provider>
    );
  }
);
