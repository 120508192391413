import { getCached, setCached } from "./caching";
import { logDebug, logError } from "./log";
import { tsNow } from "./time";

export const persistentDebounced = ({
  cacheOptions,
  durationSeconds,
  key,
  fn,
}) => {
  if (durationSeconds !== 0 && !durationSeconds) {
    logError(`Invalid debounce durationSeconds value: ${durationSeconds}`);
    durationSeconds = 30;
  }

  function debouncedFn() {
    const lastCallTsPath = `Gro.persistentDebounced.lastDisplayTs.${key}`;
    const lastCallTs = parseFloat(getCached(lastCallTsPath, cacheOptions) || 0);
    if (tsNow() - lastCallTs < durationSeconds) {
      logDebug(
        `Ignoring call to debounced fn ${key}. Already called once in the last ${durationSeconds} seconds`
      );
      return false;
    }

    setCached(lastCallTsPath, tsNow(), cacheOptions);
    return fn(...arguments);
  }

  return debouncedFn;
};

export const localStorageDebounced = ({ durationSeconds, key, fn }) =>
  persistentDebounced({
    cacheOptions: {
      useLocalStorage: true,
    },
    durationSeconds,
    key,
    fn,
  });
