import { teamUserAuth } from "api";
import { hardRedirectTo } from "tools/navigation";
import { getQueryParamValue } from "tools/url";
import LoadedComponent from "./loaded-component";

const TeamUserAuth = (props) => {
  return <h1>Loading...</h1>;
};

TeamUserAuth.loadData = async (props) => {
  const secret = getQueryParamValue("t");
  const shopName = getQueryParamValue("s");

  const result = await teamUserAuth(secret, shopName);
  if (result) {
    hardRedirectTo(`/?shop=${shopName}`);
  } else {
    alert("Could not get access token");
  }
};

export default LoadedComponent(TeamUserAuth);
