import Auth from "tools/auth";
import { isProduction, isTestStore } from "../config";
import { tsNow } from "./time";
import { getCached, setCached } from "./caching";
import { logDebug } from "./log";

export const isLogRocketEnabled =
  isProduction && !isTestStore && !Auth.isAppTeamUser();

export const supportToolsEnabled =
  isProduction && !isTestStore && !Auth.isAppTeamUser();

export const lastMsgDisplayTsCacheKey = (messageId) =>
  `Gro.supportMessages.lastDisplayTs.${messageId}`;

export const showSupportMessage = ({
  delaySecs,
  messageId,
  debounceSeconds,
}) => {
  if (!messageId) {
    throw new Error(`showSupportMessage: messageId is required`);
  }

  // Same message won't be prompted twice in this duration.
  if (debounceSeconds !== 0) {
    debounceSeconds = debounceSeconds || 600;
  }

  if (window.Beacon) {
    const lastDisplayTsPath = lastMsgDisplayTsCacheKey(messageId);
    const lastMsgDisplayTs = parseFloat(
      getCached(lastDisplayTsPath, {
        useLocalStorage: true,
      }) || 0
    );
    if (tsNow() - lastMsgDisplayTs < debounceSeconds) {
      logDebug(
        `Gro: Support message ${messageId} has already been shown in the last ${debounceSeconds}s. Ignoring request to show message.`
      );
      return;
    }

    if (delaySecs !== 0) {
      delaySecs = delaySecs || 3;
    }
    logDebug(`Showing support message ${messageId} in ${delaySecs} seconds`);
    window.Beacon("show-message", messageId, {
      delay: delaySecs * 1000,
      force: true,
    });

    setCached(lastDisplayTsPath, tsNow(), {
      useLocalStorage: true,
    });
    return true;
  }
};

export const initSupportTools = () => {
  initHelpScout();
};

const initHelpScout = () => {
  // Pasted as is from helpscout recommended script tag contents.
  // eslint-disable-next-line no-eval
  eval(`
  !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
  `);
  // eslint-disable-next-line no-eval
  eval(`window.Beacon('init', '4f9af442-dd67-4185-8e4b-aade41694211')`);
};
