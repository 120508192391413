import React, { useCallback, useEffect } from "react";
import { Modal, TextContainer, TextField } from "@shopify/polaris";
import { useState, useContext } from "react";
import happy from "../../../../images/happy.png";
import neutral from "../../../../images/neutral.png";
import sad from "../../../../images/sad.png";
import yayGif from "../../../../images/minions.gif";
import styles from "./SurveyModal.module.css";
import { localStorageDebounced } from "tools/debounce";
import { logDebug, logError } from "tools/log";
import { randomString } from "tools/random";
import { executeIntent } from "api";
import { hardRedirectTo } from "tools/navigation";

const EmojiFeedbackView = ({ onChange, onDone, reportEvent }) => {
  const onEmojiSelected = (emoji) => {
    reportEvent("emoji-feedback-received", {
      result: emoji,
    });
    onChange(emoji);
    onDone(emoji);
  };

  return (
    <TextContainer>
      <p style={{ textAlign: "center" }}>
        Please select a smiley that best represents your experience
      </p>
      <div className={styles.modal_emoji_container}>
        <img
          src={happy}
          alt="happy"
          id="happy"
          onClick={(e) => onEmojiSelected(e.target.id)}
        />
        <img
          src={neutral}
          alt="neutral"
          id="neutral"
          onClick={(e) => onEmojiSelected(e.target.id)}
        />
        <img
          src={sad}
          alt="sad"
          id="sad"
          onClick={(e) => onEmojiSelected(e.target.id)}
        />
      </div>
    </TextContainer>
  );
};

const TextFeedbackView = ({ value, onChange }) => {
  return (
    <div className={styles.second_modal}>
      <TextContainer>
        <p>Tell us more. We would love to hear from you.</p>
      </TextContainer>
      <TextField
        value={value}
        onChange={onChange}
        multiline={4}
        autoComplete="off"
        style={{ width: "200%" }}
      />
      <TextContainer>
        <p>
          Your feedback is important to us!
          <br />
          It keeps us motivated, and we will learn from it to change things to
          best suit your needs.
        </p>
      </TextContainer>
    </div>
  );
};

const ReviewRequestView = () => {
  return (
    <>
      <img
        style={{ display: "flex", margin: "0.5rem auto" }}
        src={yayGif}
        alt="Yay!!"
        width="480"
        height="216"
        allowFullScreen
      />
      <TextContainer>
        <p style={{ textAlign: "center" }}>
          Thanks! This Totally Made Our Day! <br />
          We have made the app to empower merchants like you with world class
          tools, and your appreciation means the world to us.
        </p>
        <p style={{ textAlign: "center" }}>
          Please take a moment to rate us on the Shopify App Store as well.{" "}
          <br />
          It will mean a lot to us, and it will help other merchants know what
          to expect from the app.
        </p>
      </TextContainer>
    </>
  );
};

const SurveyModal = ({ onClose, reportEvent }) => {
  const [currentView, setCurrentView] = useState("emojiFeedback");
  const [values, setValues] = useState({
    emojiFeedback: null,
    textFeedback: "",
  });
  const [isRateAppLoading, setIsRateAppLoading] = useState(false);

  const onCurrentViewDone = (result) => {
    if (currentView === "emojiFeedback") {
      if (result === "happy") {
        setCurrentView("reviewRequest");
      } else {
        setCurrentView("textFeedback");
      }
    } else if (currentView === "textFeedback") {
      reportEvent("text-feedback-received", {
        result: values?.textFeedback,
      });
      if (values?.emojiFeedback === "happy") {
        setCurrentView("reviewRequest");
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const SurveyViews = {
    emojiFeedback: {
      component: EmojiFeedbackView,
      modalProps: {
        title: "How are you liking it so far?",
      },
    },
    textFeedback: {
      component: TextFeedbackView,
      modalProps: {
        title: "How are you Liking it so far?",
        primaryAction: {
          content: "Continue",
          onAction: onCurrentViewDone,
        },
      },
    },
    reviewRequest: {
      component: ReviewRequestView,
      modalProps: {
        title: "Thank you so much!",
        primaryAction: {
          content: "Rate App",
          loading: isRateAppLoading,
          onAction: async () => {
            let redirectPromise = null;
            try {
              // window.open(
              // "https://apps.shopify.com/gro-upsell?#modal-show=ReviewListingModal"
              // );
              setIsRateAppLoading(true);
              redirectPromise = hardRedirectTo(
                "https://apps.shopify.com/gro-upsell?#modal-show=ReviewListingModal"
              );
            } finally {
              reportEvent("review-requested", {
                result: "accepted",
              });
            }
            if (redirectPromise) {
              await redirectPromise;
            }
            onCurrentViewDone();
          },
        },
        secondaryActions: [
          {
            content: "Maybe Later",
            onAction: () => {
              reportEvent("review-requested", {
                result: "declined",
              });
              onCurrentViewDone();
            },
          },
        ],
      },
    },
  };

  const CurrentViewComponent = SurveyViews[currentView]?.component;
  if (!CurrentViewComponent) {
    logError(`Invalid current survey modal view: ${currentView}`);
    return null;
  }

  const currentValue = values[currentView];
  const onChange = (newVal) => {
    const newValues = {
      ...values,
      [currentView]: newVal,
    };
    setValues(newValues);
  };

  return (
    <div>
      <div style={{ height: "500px" }}>
        <Modal
          open={true}
          onClose={() => {
            reportEvent("modal-closed");
            onClose();
          }}
          {...(SurveyViews[currentView]?.modalProps || {})}
        >
          <Modal.Section>
            <CurrentViewComponent
              value={currentValue}
              onChange={onChange}
              onDone={onCurrentViewDone}
              reportEvent={reportEvent}
            />
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );
};

const SurveyModalContext = React.createContext({});
export const useSurveyModal = () => useContext(SurveyModalContext);

const SurveyModalProvider = ({
  lastAppStoreReviewTs,
  lastSurveyInteractionTs,
  children,
}) => {
  const [modalKey, setModalKey] = useState(null);
  const openSurvey = () => {
    if (lastAppStoreReviewTs) {
      logDebug("Already left app store review.");
      return false;
    }

    // supplied by server. useful when localstorage is not available
    // if (tsNow() - lastSurveyInteractionTs < 3600 * 24 * 15) {
    //   logDebug(
    //     `Not showing survey modal. Already shown once in the last 15 days`
    //   );
    //   return false;
    // }

    if (modalKey === null) {
      setModalKey(randomString(6));
      return true;
    }
    return false;
  };
  const debouncedOpenSurvey = localStorageDebounced({
    fn: openSurvey,
    durationSeconds: 3600 * 24 * 30,
    key: "survey-with-review-request",
  });

  const closeSurvey = () => setModalKey(null);

  const reportEvent = useCallback(
    async (name, data) => {
      if (modalKey === null) {
        throw new Error(
          "Invariant violation: survey event being reported with null modalKey"
        );
      }

      const eventType = `review-request-survey-event`;
      const response = await executeIntent("gro-core/report-app-domain-event", {
        event_type: eventType,
        data: {
          survey_id: modalKey,
          event: name,
          data,
        },
      });
      return response;
    },
    [modalKey]
  );

  useEffect(() => {
    if (modalKey) {
      reportEvent("modal-opened", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalKey]);

  return (
    <SurveyModalContext.Provider
      value={{
        openSurvey: debouncedOpenSurvey,
      }}
    >
      {children}
      {modalKey !== null && (
        <SurveyModal
          key={modalKey}
          onClose={closeSurvey}
          reportEvent={reportEvent}
        />
      )}
    </SurveyModalContext.Provider>
  );
};

export default SurveyModalProvider;
