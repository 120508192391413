import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";

import { Provider } from "@shopify/app-bridge-react";

import { isShopifyEmbedded } from "@shopify/app-bridge-utils";

import { appBridgeConfig } from "../tools/shopify-app-bridge";
import { Loader } from "../tools/components/loaded-component";
import { getCached } from "tools/caching";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const WaitingRedirectContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const AppShell = (props) => {
  if (!isShopifyEmbedded()) {
    return (
      <WaitingRedirectContainer>
        <Loader>Redirecting you to Shopify admin</Loader>
      </WaitingRedirectContainer>
    );
  }

  return <Container>{props.children}</Container>;
};

const AppShellWithShopifyAppBridge = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path) => navigate(path, { replace: true }) }),
    [navigate]
  );
  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  if (getCached("is_team_user")) {
    return <Container>{props.children}</Container>;
  }

  const shopifyAppBridgeConfig = appBridgeConfig();

  // The App bridge `Provider` will automatically redirect to
  // the embedded app view if opened independently
  return (
    <Provider config={shopifyAppBridgeConfig} router={router}>
      <AppShell>{props.children}</AppShell>
    </Provider>
  );
};

export default AppShellWithShopifyAppBridge;
