import createApp from "@shopify/app-bridge";
import { isShopifyEmbedded, getSessionToken } from "@shopify/app-bridge-utils";
import { useAppBridge } from "@shopify/app-bridge-react";
import { ResourcePicker, Modal, Redirect } from "@shopify/app-bridge/actions";
import { assert } from "./validations";

import { SHOPIFY_API_KEY } from "../config";
import {
  getCached,
  getCachedCollection,
  getCachedProduct,
  getCachedShopProp,
  updateCollectionsCache,
  updateProductCache,
} from "./caching";
import { getQueryParamValue } from "./url";
import { logError } from "./log";
import { extractIntIdIfShopifyGId } from "./shopify";
import { camelCaseToSnakeCase } from "./strings";

// TODO(Raj): Combine this file with tools/app-bridge

export const appBridgeConfig = () => {
  // Cached on app init (MerchantApp.loadData for now)
  // Always prefer using Shopify specified host value
  // and treat it as an opaque value.
  let host = getQueryParamValue("host") || getCached("shopify-host");

  if (!host) {
    logError(
      "Could not find cached shopify-host value. Calculating based on known convention"
    );

    let myshopifyDomain = getQueryParamValue("shop");
    if (!myshopifyDomain) {
      myshopifyDomain = getCachedShopProp("myshopify_domain");
    }

    if (!myshopifyDomain) {
      throw new Error(`myshopify domain could not be deduced`);
    }

    if (!myshopifyDomain.includes("myshopify.com")) {
      myshopifyDomain += ".myshopify.com";
    }

    host = window.btoa(`${myshopifyDomain}/admin`);
  }

  return {
    apiKey: SHOPIFY_API_KEY,
    host,
  };
};

export const requestSessionToken = async () => {
  const app = createApp(appBridgeConfig());
  const sessionToken = await getSessionToken(app);
  return sessionToken;
};

export const embeddedAppRedirect = (url, newContext) => {
  if (!isShopifyEmbedded()) {
    throw new Error(`embeddedAppRedirect called but app not embedded`);
  }

  const app = createApp(appBridgeConfig());

  if (!url.startsWith("http")) {
    return Redirect.create(app).dispatch(Redirect.Action.APP, url);
  }

  let adminPath = null;
  if (url.startsWith(`${app.hostOrigin}/admin`)) {
    adminPath = url.replace(`${app.hostOrigin}/admin`, "");
  } else if (url.startsWith("https://admin.shopify.com/store")) {
    adminPath = "/" + url.split("/").slice(5).join("/");
  }
  if (adminPath && !newContext) {
    return Redirect.create(app).dispatch(Redirect.Action.ADMIN_PATH, adminPath);
  }

  return Redirect.create(app).dispatch(Redirect.Action.REMOTE, {
    url,
    newContext: Boolean(newContext),
  });
};

export const useCollectionPicker = ({ selectMultiple }) => {
  const app = useAppBridge();

  const openCollectionPicker = ({
    onSelected,
    onCancelled,
    initialSelections, // Expected to be a list collection ids.
  }) => {
    const pickerOptions = {
      actionVerb: "select",
      selectMultiple: Boolean(selectMultiple),
      showDraft: false,
      showArchived: false,
    };

    if (initialSelections) {
      pickerOptions.initialSelectionIds = initialSelections.map((cid) => ({
        id: `gid://shopify/Collection/${extractIntIdIfShopifyGId(cid)}`,
      }));
    }

    assert(onSelected, "onSelected is required for openCollectionPicker");

    const picker = ResourcePicker.create(app, {
      resourceType: ResourcePicker.ResourceType.Collection,
      options: pickerOptions,
    });

    picker.subscribe(ResourcePicker.Action.SELECT, (selectionPayload) => {
      const selectedCollections = (selectionPayload.selection || []).map(
        normalizeResourcePickerCollection
      );

      // Add or update discovered collection data in cache
      selectedCollections.forEach((c) => {
        const cid = extractIntIdIfShopifyGId(c.id);
        if (!getCachedCollection(cid)) {
          // Only update cache if empty.
          // Data coming from Gro backend has more details and takes precedence.
          updateCollectionsCache(cid, c);
        }
      });

      onSelected(selectedCollections);
    });

    if (onCancelled) {
      picker.subscribe(ResourcePicker.Action.CANCEL, onCancelled);
    }

    picker.dispatch(ResourcePicker.Action.OPEN);
  };

  return {
    openCollectionPicker,
  };
};

export const useProductPicker = ({ selectMultiple, showVariants }) => {
  const app = useAppBridge();

  const openProductPicker = ({
    onSelected,
    onCancelled,
    initialSelections, // Expected to be [{id: pid, variant_ids: [...]}, ...]
  }) => {
    const productPickerOptions = {
      actionVerb: "select",
      selectMultiple: Boolean(selectMultiple),
      showVariants: Boolean(showVariants),
      showDraft: false,
      showArchived: false,
    };

    if (initialSelections) {
      productPickerOptions.initialSelectionIds = initialSelections.map((p) => {
        const selection = {
          id: `gid://shopify/Product/${extractIntIdIfShopifyGId(p.id)}`,
        };
        if (p.variant_ids) {
          selection.variants = p.variant_ids.map((vid) => ({
            id: `gid://shopify/ProductVariant/${extractIntIdIfShopifyGId(vid)}`,
          }));
        }
        return selection;
      });
    }

    assert(onSelected, "onSelected is required for openProductPicker");

    const picker = ResourcePicker.create(app, {
      resourceType: ResourcePicker.ResourceType.Product,
      options: productPickerOptions,
    });

    picker.subscribe(ResourcePicker.Action.SELECT, (selectionPayload) => {
      const selectedProducts = (selectionPayload.selection || []).map(
        normalizeResourcePickerProduct
      );

      // Add or update discovered product data in cache
      selectedProducts.forEach((p) => {
        const pid = extractIntIdIfShopifyGId(p.id);
        if (!getCachedProduct(pid)) {
          // Only update cache if empty.
          // Data coming from Gro backend has more details and takes precedence.
          updateProductCache(pid, p);
        }
      });

      onSelected(selectedProducts);
    });

    if (onCancelled) {
      picker.subscribe(ResourcePicker.Action.CANCEL, onCancelled);
    }

    picker.dispatch(ResourcePicker.Action.OPEN);
  };

  return {
    openProductPicker,
  };
};

export const useAppBridgeModal = () => {
  const app = useAppBridge();

  const openModal = ({ onClosed, ...modalOptions }) => {
    const modal = Modal.create(app, modalOptions);

    if (onClosed) {
      modal.subscribe(Modal.Action.CLOSE, onClosed);
    }

    modal.dispatch(Modal.Action.OPEN);
  };

  return {
    open: openModal,
  };
};

// Helper
// {a: undefined, b: null, c: [], d: false, e: '', f: 0} => {c: [], d: false, e: '', f: 0}
const filterNullAndUndefinedValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => ![null, undefined].includes(v))
  );
};

const normalizeImage = (img) => {
  if (!img) return img;

  const normalized = {
    id: extractIntIdIfShopifyGId(img.id),
    alt: img.altText,
    src: img.src || img.originalSrc,
    width: img.width,
    height: img.height,
  };
  return filterNullAndUndefinedValues(normalized);
};

export const normalizeResourcePickerProduct = (productData) => {
  // Normalizes data returned from product picker to the shape
  // used by backend for product data returned through the API.
  if (!productData) return productData;

  const normalizedProduct = {
    id: extractIntIdIfShopifyGId(productData.id),
    title: productData.title,
    handle: productData.handle,
    description_html: productData.descriptionHtml,
    updated_at: productData.updatedAt,
    tags: productData.tags,
    status: productData.status && productData.status.toLowerCase(),
  };

  normalizedProduct.images = (productData.images || []).map(normalizeImage);

  if (normalizedProduct.images.length > 0) {
    normalizedProduct.featured_image = normalizedProduct.images[0];
  }

  const options = (productData.options || []).map(
    ({ name, position, values }) => ({
      name,
      position,
      values,
    })
  );
  if (options.length > 0) {
    normalizedProduct.options = options;
  }

  const variants = (productData.variants || []).map((v) => {
    const normalizedVariant = { id: extractIntIdIfShopifyGId(v.id) };

    const propsToCopy = [
      "title",
      "price",
      "compareAtPrice",
      "weight",
      "weightUnit",
      "taxable",
      "position",
      "inventoryPolicy",
      "inventoryQuantity",
      "updatedAt",
    ];
    propsToCopy.forEach((prop) => {
      if (v[prop]) {
        normalizedVariant[camelCaseToSnakeCase(prop)] = v[prop];
      }
    });

    if (normalizedVariant.weight_unit) {
      const normalizedUnits = Object.fromEntries(
        ["g", "kg", "oz", "lb"].map((u) => [u, u])
      );
      Object.assign(normalizedUnits, {
        kilograms: "kg",
        grams: "g",
        pounds: "lb",
        ounces: "oz",
      });
      normalizedVariant.weight_unit =
        normalizedUnits[normalizedVariant.weight_unit.toLowerCase()];
    }

    if (v.selectedOptions) {
      v.selectedOptions.forEach((o, idx) => {
        normalizedVariant[`option${idx + 1}`] = o.value;
      });
    }

    if (v.image?.id) {
      normalizedVariant.image_id = extractIntIdIfShopifyGId(v.image.id);
    }

    if (normalizedVariant.inventory_policy) {
      normalizedVariant.inventory_policy =
        normalizedVariant.inventory_policy.toLowerCase();
    }

    return normalizedVariant;
  });
  if (variants.length > 0) {
    normalizedProduct.variants = variants;
  }

  return filterNullAndUndefinedValues(normalizedProduct);
};

export const normalizeResourcePickerCollection = (collectionData) => {
  // Normalizes data returned from product picker to the shape
  // used by backend for product data returned through the API.
  if (!collectionData) return collectionData;

  const normalizedCollection = {
    id: extractIntIdIfShopifyGId(collectionData.id),
    title: collectionData.title,
    handle: collectionData.handle,
    image: normalizeImage(collectionData.image),
  };

  return normalizedCollection;
};
