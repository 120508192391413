import { Modal } from "@shopify/polaris";
import styled from "styled-components";
import { executeIntent } from "api";
import React, { useEffect, useState } from "react";
import { getCachedShopProp } from "tools/caching";
import { hardRedirectTo } from "tools/navigation";
import postPurchaseAppSelectionGif from "images/post-purchase-app-selection.gif";

const AppSelectionDemoContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AppSelectionDemoImg = styled.img`
  width: 98%;
`;

// Global state about whether the user has conigured this app
// as the post purchase app of choice in checkout settings.
export const IsSelectedPostPurchaseApp = React.createContext({
  isSelected: false,
  isLoading: false,
  isSnoozed: false,
});

export const IsSelectedPostPurchaseAppProvider = React.memo(
  ({ value, children }) => {
    if (value === undefined) {
      value = true;
    }
    const [isSelected, setIsSelected] = useState(value);
    const [isLoading, setIsLoading] = useState(false);
    const [isSnoozed, setIsSnoozed] = useState(false);
    const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

    const onReload = async () => {
      setIsLoading(true);
      try {
        const { data } = await executeIntent(
          "shopify/is_selected_post_purchase_app",
          {}
        );
        if (data) {
          setIsSelected(Boolean(data.is_selected_post_purchase_app));
        }
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      const timeoutDelay = [
        "/onboarding/autopilot",
        "/onboarding/rule",
        "/",
      ].includes(window.location.pathname)
        ? 1500
        : 0;
      const timeoutId = setTimeout(() => onReload(), timeoutDelay);
      return () => clearTimeout(timeoutId);
    }, []);

    const openSettings = () => {
      const shopName = getCachedShopProp("shop_name");
      hardRedirectTo(
        `https://admin.shopify.com/store/${shopName}/settings/checkout#post-purchase`
      );
    };

    const onSelectPostPurchaseApp = () => setIsDemoModalOpen(true);

    return (
      <IsSelectedPostPurchaseApp.Provider
        value={{
          isSelected,
          isLoading,
          isSnoozed,
          openCheckoutSettings: onSelectPostPurchaseApp,
          onReload,
          onSnooze: () => setIsSnoozed(true),
        }}
      >
        {children}
        <Modal
          open={isDemoModalOpen}
          title="Select Gro as Your Post Purchase App"
          primaryAction={{
            id: "post-purchase-app-selection-demo-cta",
            content: "Open Checkout Settings",
            onAction: openSettings,
            loading: isLoading,
          }}
          onClose={() => setIsDemoModalOpen(false)}
        >
          <Modal.Section>
            <AppSelectionDemoContainer>
              <AppSelectionDemoImg
                src={postPurchaseAppSelectionGif}
                alt="a demonstration of selecting Gro as the Post Purchase App on your Store"
              />
            </AppSelectionDemoContainer>
          </Modal.Section>
        </Modal>
      </IsSelectedPostPurchaseApp.Provider>
    );
  }
);
