import _ from "lodash";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LogRocket from "logrocket";

import { isShopifyEmbedded } from "@shopify/app-bridge-utils";

import { executeIntent } from "../api";
import LoadedComponent from "tools/components/loaded-component";
import Auth from "../tools/auth";
import { logError } from "../tools/log";
import AppShell from "./app-shell";
import { getCachedShopProp, setCached, setCachedShop } from "../tools/caching";
import { IsSelectedPostPurchaseAppProvider } from "./post-purchase-app-selection/context";
import { BillingPlanProvider } from "./billing-plan-context";
import { getQueryParamValue } from "tools/url";
import { useNavigate } from "tools/navigation";
import { RouteWrapper } from "tools/routes";
import { ResourceSyncStatusProvider } from "./resource-sync-context";
import { TranslationsProvider } from "./translations/context";
import { isLogRocketEnabled } from "tools/support";
import { GroResourcePickerProvider } from "tools/components/resource-pickers";
import SurveyModalProvider from "pages/home/components/SurveyModal/SurveyModal";
import { AutopilotTrainingStatusProvider } from "pages/autopilot/training-status";
import {
  OnboardingStatus,
  OnboardingStatusProvider,
} from "./onboarding-context";

const AnalyticsPage = React.lazy(() => import("../pages/analytics"));
const AutopilotAdmin = React.lazy(() => import("../pages/autopilot"));
const BillingPage = React.lazy(() => import("../pages/billing"));
const RuleEditor = React.lazy(() => import("../pages/rule-editor"));
const HomePage = React.lazy(() => import("../pages/home"));
const OnboardingPage = React.lazy(() => import("../pages/onboarding"));
const FAQPage = React.lazy(() => import("../pages/FAQ"))

const MerchantAppRoutes = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/analytics/*"
        element={
          <AnalyticsPage
            breadcrumbs={[
              {
                content: "Back",
                onAction: () => navigate("/"),
              },
            ]}
          />
        }
      />
      <Route
        path="/billing/*"
        element={
          <BillingPage
            breadcrumbs={[
              {
                content: "Back",
                onAction: () => navigate("/"),
              },
            ]}
          />
        }
      />
      <Route
        path="/rules/create/*"
        element={
          <RuleEditor
            breadcrumbs={[
              {
                content: "Back",
                onAction: () => navigate("/"),
              },
            ]}
          />
        }
      />
      <Route
        path="/autopilot/*"
        element={
          <AutopilotAdmin
            ruleOverviewPath="/autopilot"
            breadcrumbs={[
              {
                content: "Back",
                onAction: () => navigate("/"),
              },
            ]}
          />
        }
      />
      <Route
        path="/rule/:id/*"
        element={
          <RouteWrapper
            component={RuleEditor}
            routeParamsToPassDown={["id"]}
            componentProps={{
              breadcrumbs: [
                {
                  content: "Back",
                  onAction: () => navigate("/"),
                },
              ],
            }}
          />
        }
      />
      <Route
      path = "/faq"
      element={<FAQPage/>}
      />
      {/*TODO(Raj): * should go to 404 maybe?*/}
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
};

function MerchantApp(props) {
  if (!Auth.isAuthenticated()) {
    const shopHint = Auth.getOauthShopHint();
    const redirectUrl = "/connect" + (shopHint ? `?shop=${shopHint}` : "");
    return <Navigate to={redirectUrl} replace={true} />;
  }

  return (
    <AppShell shop={props.loadedData?.authResult?.shop}>
      <OnboardingStatusProvider
        initState={props.loadedData?.authResult?.onboarding_state}
      >
        <IsSelectedPostPurchaseAppProvider>
          <ResourceSyncStatusProvider
            initState={props.loadedData?.authResult?.entity_sync_status}
          >
            <AutopilotTrainingStatusProvider
              initState={
                props.loadedData?.authResult?.autopilot_training_status
              }
            >
              <GroResourcePickerProvider>
                <TranslationsProvider>
                  <BillingPlanProvider>
                    <SurveyModalProvider
                      lastAppStoreReviewTs={
                        props?.loadedData?.authResult
                          ?.latest_event_timestamps?.[
                          "shopify/app-store/left-review"
                        ]
                      }
                      lastSurveyInteractionTs={
                        props?.loadedData?.authResult
                          ?.latest_event_timestamps?.[
                          "review-request-survey-event"
                        ]
                      }
                    >
                      <OnboardingStatus.Consumer>
                        {({ isOnboardingComplete, onOnboardingCompleted }) => {
                          return !isOnboardingComplete ? (
                            <OnboardingPage
                              initState={
                                props.loadedData?.authResult?.onboarding_state
                              }
                              onOnboardingComplete={onOnboardingCompleted}
                            />
                          ) : (
                            <MerchantAppRoutes />
                          );
                        }}
                      </OnboardingStatus.Consumer>
                    </SurveyModalProvider>
                  </BillingPlanProvider>
                </TranslationsProvider>
              </GroResourcePickerProvider>
            </AutopilotTrainingStatusProvider>
          </ResourceSyncStatusProvider>
        </IsSelectedPostPurchaseAppProvider>
      </OnboardingStatusProvider>
    </AppShell>
  );
}

// When landing, check in with the backend and get data to init the app.
MerchantApp.loadData = async (props) => {
  let shop = getQueryParamValue("shop");
  if (shop) {
    // Used by /connect to start the oauth flow without user intervention
    Auth.setOauthShopHint(shop);
  }

  let authPayload = {};

  setCached("shopify-host", getQueryParamValue("host"));

  if (Auth.isAuthenticated()) {
    // Already authenticated with a stored access token
    shop = getCachedShopProp("myshopify_domain") || shop;
    if (!shop) {
      logError(`Authenticated but no cached shop`);
    }
  } else if (getQueryParamValue("gro_jwt")) {
    // Landed post oauth with temp Gro issued auth token
    if (!shop) {
      logError(`gro_jwt in query params but not shop`);
    } else {
      const groAuthJwt = getQueryParamValue("gro_jwt");
      authPayload = {
        gro_jwt: groAuthJwt,
        shop: shop,
      };
    }
  }

  // If there are no creds to check in with, do nothing.
  // MerchantApp is expected to navigate to /connect
  if (!Auth.isAuthenticated() && _.isEmpty(authPayload)) {
    return {};
  }

  if (shop && isLogRocketEnabled) {
    let shopDomain = shop;
    if (!shopDomain.includes("myshopify.com")) {
      shopDomain = `${shopDomain}.myshopify.com`;
    }
    LogRocket.identify(shopDomain, {
      shop: shopDomain,
    });
  }

  const { data: authResult } = await executeIntent(
    "auth/merchant",
    authPayload
  );
  if (!authResult) {
    // api.js takes care of starting oAuth flow whenever it sees a 401
    logError(
      "Intent auth/merchant returned non-401 response without authResult"
    );
    return {};
  }

  if (!isShopifyEmbedded()) {
    window.history.replaceState({}, window.title, window.location.pathname); // Clear query params.
  }

  if (!authResult.shop) {
    throw new Error(`Merchant auth response did not include shop name`);
  }
  setCachedShop(authResult.shop);
  setCached("tenantId", authResult.tenant_id);

  const accessToken = authResult?.access_token;
  if (accessToken) {
    Auth.setAccessToken(accessToken);
    delete authResult.access_token;
  }

  // Id customer for helpscout beacon
  //const shopEmail =
  //  _.get(authResult, "shop.customer_email") || _.get(authResult, "shop.email");
  //if (shopEmail && window.Beacon) {
  //  // TODO(Raj): Pipe back any confirmed emails into our DB and use identify from there.
  //  window.Beacon("prefill", {
  //    email: shopEmail,
  //  });
  //}

  return {
    authResult,
  };
};

export default LoadedComponent(MerchantApp);
