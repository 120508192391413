import axios from "axios";

import { GRO_PLATFORM_BASE_URL } from "config";
import Auth from "tools/auth";
import { isNonEmptyStr } from "tools/validations";
import { hardRedirectTo } from "tools/navigation";
import { logDebug } from "tools/log";
import { getQueryParamValue } from "tools/url";

const makeApiCallWith5XXRetries = async (makeApiCall, options) => {
  const maxRetries = options?.maxRetries || 3;

  for (let attempt = 0; attempt <= maxRetries; attempt++) {
    try {
      const result = await makeApiCall();
      return result;
    } catch (error) {
      const responseStatus = error?.response?.status;
      const isTimeout = Boolean(error.message.match(/.*timeout.*exceeded/));
      const isRetriable = [502, 503, 504].includes(responseStatus) || isTimeout;
      const isLastAttempt = attempt === maxRetries;
      if (!isRetriable || isLastAttempt) {
        throw error;
      } else {
        const backoffMillis = (attempt + 1) * 1000; // Naive backoff.
        console.warn(
          `Waiting ${backoffMillis}ms and retrying api call for status ${responseStatus}`
        );
        await new Promise((resolve) => setTimeout(resolve, backoffMillis));
      }
    }
  }
};

const baseOptions = {
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
};

export const api = axios.create({
  ...baseOptions,
  baseURL: `${GRO_PLATFORM_BASE_URL}/api/v1`,
});

export const executeIntent = async (intent, payload, options) => {
  // Invariant.
  if (!isNonEmptyStr(intent)) {
    throw new Error("Intent must be non-empty");
  }

  const headers = {};
  if (Auth.getAccessToken()) {
    headers["Authorization"] = Auth.getAccessToken();
  }

  try {
    const startTs = new Date().getTime();
    logDebug(`${intent} Requesting`);
    const response = await makeApiCallWith5XXRetries(() =>
      api.post(
        `dispatch_intent/${intent}`,
        {
          payload,
        },
        { headers, timeout: 6000 }
      )
    );
    logDebug(`${intent} Finished. Took ${new Date().getTime() - startTs}`);
    return response?.data || {};
  } catch (error) {
    const responseStatus = error?.response?.status;
    if (responseStatus === 401) {
      logDebug("Need authentication/installation");
      Auth.clearAccessToken();

      const shop = getQueryParamValue("shop");
      if (shop) {
        await startShopOauth(shop);
      } else {
        let redirectUrl = "/connect";
        await hardRedirectTo(redirectUrl);
      }
    }

    const nonExceptionalResponseCodes = [400, 403, 404];
    if (nonExceptionalResponseCodes.includes(responseStatus)) {
      return { error, responseStatus };
    }

    console.error(`Error dispatching intent ${intent} - ${error}`);
    if (!options?.ignoreErrors) {
      window.alert(
        `There was an error while making a request over the network. Please try again after some time.`
      );
      throw error;
    }
  }
};

// Auth related API helpers.
const auth = axios.create({
  ...baseOptions,
  baseURL: `${GRO_PLATFORM_BASE_URL}/auth`,
});

export const startShopOauth = async (shop, postAuthRedirectAppPath) => {
  const oauthRequestPayload = { shop };
  if (postAuthRedirectAppPath) {
    oauthRequestPayload.destination_app_path = postAuthRedirectAppPath;
  }

  try {
    const { data } = await makeApiCallWith5XXRetries(() =>
      auth.post("request_shop_oauth", oauthRequestPayload, { timeout: 6000 })
    );
    const grantUrl = data?.data?.grant_url;
    if (!grantUrl) {
      throw new Error(`Did not find grant_url in request_shop_oauth response.`);
    }
    await hardRedirectTo(grantUrl);
    // hardRedirectTo should have changed window.location by now.
    // If we reach here, something went wrong.
    throw new Error("Redirecting to shopify is taking too long");
  } catch (error) {
    window.alert(`Error requesting shop oauth ${error}`);
    throw error;
  }
};

export const teamUserAuth = async (secret, shopName) => {
  try {
    const result = await makeApiCallWith5XXRetries(() =>
      auth.post(
        "auth_team_user_for_tenant",
        { secret, tenant_name: shopName },
        { timeout: 6000 }
      )
    );

    const accessToken = result?.data?.data?.access_token;
    if (!accessToken) {
      throw new Error("No access token in result.");
    }

    Auth.setAppTeamUserAccessToken(accessToken);

    return accessToken;
  } catch (error) {
    window.alert(`Error requesting app team oauth ${error}`);
    throw error;
  }
};
