import { executeIntent } from "api";
import React, { useEffect, useState } from "react";
import { logError } from "tools/log";

// Global state about the user's active billing plan
export const BillingPlan = React.createContext({
  plan: null,
  isLoading: null,
  onReload: null,
  activatePlan: null,
});

export const BillingPlanProvider = React.memo(({ value, children }) => {
  const [plan, setPlan] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  const onReload = async () => {
    setIsLoading(true);
    try {
      const { data } = await executeIntent("billing/get_active_plan", {});
      if (data?.active_plan) {
        setPlan(data?.active_plan);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadDelay = window.location.pathname.includes("/billing") ? 0 : 3000;

    const timeoutId = setTimeout(() => onReload(), loadDelay);
    return () => clearTimeout(timeoutId);
  }, []);

  const activatePlan = async (planRequestId) => {
    setIsLoading(true);
    try {
      const result = await executeIntent("billing/activate_tenant_plan", {
        request_id: planRequestId,
        proof_of_payment: {},
      });

      if (result?.data?.id) {
        setPlan(result?.data);
      } else {
        logError(
          "Could not activate plan for request",
          planRequestId,
          "result",
          result
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BillingPlan.Provider
      value={{
        plan,
        isLoading,
        onReload,
        activatePlan,
      }}
    >
      {children}
    </BillingPlan.Provider>
  );
});
