import _ from "lodash";
import styled from "styled-components";
import { createContext, useContext, useMemo, useState } from "react";
import { isShopifyEmbedded } from "@shopify/app-bridge-utils";
import { Checkbox, Icon, Modal, TextField, Thumbnail } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { ResourceSyncStatus } from "merchant-app/resource-sync-context";
import {
  useProductPicker as useAppBridgeProductPicker,
  useCollectionPicker as useAppBridgeCollectionPicker,
} from "tools/shopify-app-bridge";
import LoadedComponent from "./loaded-component";
import { executeIntent } from "api";
import { getCachedProduct, getCachedCollection } from "tools/caching";
import { upsertCachedEntity } from "tools/caching";
import { ParentSizedCenteredContainer } from ".";

const ResourceListItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 0.0625rem solid #dde0e4;

  &:hover {
    background-color: #f1f2f4;
  }
`;

const ResourceListItem = ({ resource, isSelected, onClick }) => {
  const title = resource?.title;
  const imgSrc = resource?.featured_image?.src || resource?.image?.src;

  return (
    <ResourceListItemContainer onClick={onClick}>
      <Checkbox checked={isSelected} />
      <Thumbnail source={imgSrc} alt={title} size="small" />
      <div>{title}</div>
    </ResourceListItemContainer>
  );
};

function QueriedShopifyResources({ loadedData, selected, onChange }) {
  const resources = loadedData?.resources || [];

  if (resources.length < 1) {
    return (
      <ParentSizedCenteredContainer>
        No resources found.
      </ParentSizedCenteredContainer>
    );
  }

  return (
    <div style={{ maxHeight: 600, overflowY: "auto" }}>
      {resources.map((r, idx) => (
        <ResourceListItem
          key={idx}
          resource={r}
          isSelected={selected?.includes(r?.id)}
          onClick={() => {
            if (!selected?.includes(r.id)) {
              onChange([r.id, ...(selected || [])]);
            } else {
              onChange(selected.filter((sr) => r.id !== sr));
            }
          }}
        />
      ))}
    </div>
  );
}

QueriedShopifyResources.loadData = async ({
  resourceType,
  query,
  offset,
  count,
}) => {
  const { data: resources } = await executeIntent("shopify/query_resources", {
    resource_type: resourceType,
    query,
    offset,
    count,
  });

  (resources || []).forEach((r) => upsertCachedEntity(resourceType, r.id, r));

  return { resources };
};

// eslint-disable-next-line no-func-assign
QueriedShopifyResources = LoadedComponent(QueriedShopifyResources);

const ShopifyResourcePicker = ({ resourceType, selected, onChange }) => {
  const [query, setQuery] = useState("");

  const [loadedResourcesQuery, setLoadedResourcesQuery] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reloadResources = useMemo(
    () => _.debounce(setLoadedResourcesQuery, 500),
    []
  );

  return (
    <div>
      <div style={{ padding: "1rem" }}>
        <TextField
          placeholder={`Search ${resourceType}`}
          value={query}
          onChange={(newQuery) => {
            setQuery(newQuery);
            reloadResources(newQuery);
          }}
          prefix={<Icon source={SearchMinor} />}
        />
      </div>
      <div style={{ height: 600 }}>
        <QueriedShopifyResources
          key={loadedResourcesQuery}
          resourceType={resourceType}
          query={query}
          selected={selected}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const GroResourcePickerContext = createContext({});

export const GroResourcePickerProvider = ({ children }) => {
  const [currentPickerPayload, setCurrentPickerPayload] = useState(null);

  const makePickerOpener = ({ resourceType, selectMultiple }) => {
    return ({ onSelected, onCancelled, initialSelections }) =>
      setCurrentPickerPayload({
        resourceType,
        selectMultiple,
        selected: (initialSelections || []).map((s) => s.id),
        onSelected,
        onCancelled,
      });
  };

  const onCancelled = () => {
    currentPickerPayload?.onCancelled && currentPickerPayload?.onCancelled();
    setCurrentPickerPayload(null);
  };

  const onSelect = () => {
    const selected = (currentPickerPayload.selected || []).map((rid) =>
      currentPickerPayload.resourceType === "products"
        ? getCachedProduct(rid)
        : getCachedCollection(rid)
    );
    currentPickerPayload.onSelected(selected);
    setCurrentPickerPayload(null);
  };

  const currentResourceType = currentPickerPayload?.resourceType;

  return (
    <GroResourcePickerContext.Provider
      value={{
        makePickerOpener,
      }}
    >
      {children}
      <ResourceSyncStatus.Consumer>
        {({ syncedResources }) => {
          return (
            <Modal
              title={`Select ${currentResourceType?.substr(
                0,
                currentResourceType.length - 1
              )}`}
              open={Boolean(currentPickerPayload)}
              onClose={onCancelled}
              primaryAction={{
                content: "Select",
                onClick: onSelect,
              }}
            >
              {syncedResources?.includes(currentResourceType) ? (
                <ShopifyResourcePicker
                  resourceType={currentResourceType}
                  selected={currentPickerPayload?.selected || []}
                  onChange={(newSelected) =>
                    setCurrentPickerPayload({
                      ...currentPickerPayload,
                      selected: newSelected,
                    })
                  }
                />
              ) : (
                <h3> Resource sync not complete yet for {}</h3>
              )}
            </Modal>
          );
        }}
      </ResourceSyncStatus.Consumer>
    </GroResourcePickerContext.Provider>
  );
};

export const useGroResourcePicker = ({
  resourceType,
  selectMultiple, // For now.
}) => {
  const { makePickerOpener } = useContext(GroResourcePickerContext);

  const openPicker = makePickerOpener({ resourceType, selectMultiple });

  return { openPicker };
};

export const useProductPicker = (options) => {
  if (isShopifyEmbedded()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAppBridgeProductPicker(options);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { openPicker } = useGroResourcePicker({
      resourceType: "products",
      ...options,
    });
    return { openProductPicker: openPicker };
  }
};

export const useCollectionPicker = (options) => {
  if (isShopifyEmbedded()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useAppBridgeCollectionPicker(options);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { openPicker } = useGroResourcePicker({
      resourceType: "collections",
      ...options,
    });
    return { openCollectionPicker: openPicker };
  }
};
